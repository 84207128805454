import useVuelidate from "@vuelidate/core";
import get from "lodash/get"
import set from "lodash/set"
import countries from "@/store/db/countries/items"
import datetimeFormats from "@/i18n/datetimeFormats";

export default {
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {

        return {
            t_path: null,
            processing: false,
            validated: false,
            loaded: false,
        }
    },
    methods: {
        d(date, type, locale) {

            // Check for user has selected country and selected language does not have locale
            if (!locale && this.$i18n.locale && this.$store.getters["user/profile/country"]) {

                const country = countries.find(c => c.alpha3 == this.$store.getters["user/profile/country"]);

                if (country) {

                    locale = Intl.DateTimeFormat
                        .supportedLocalesOf(
                            [
                                `${this.$i18n.locale.split("-")[0]}-${country.alpha2}`,
                                this.$i18n.locale,
                                this.$i18n.locale.split("-")[0]
                            ],
                            {localeMatcher: "lookup"}
                        )
                        .filter(locale => "undefined" !== typeof datetimeFormats[locale])
                        .reduce(
                            (result, locale) => result
                                ? result
                                : (locale == new Intl.DateTimeFormat(locale).resolvedOptions().locale ? locale : result),
                            locale
                        );
                }
            }

            return this.$d(date, type, locale);
        },
        t() {

            arguments[0] = this.t_path ? `${this.t_path}.${arguments[0]}` : arguments[0];

            return this.$t.apply(this, arguments);
        },
        te(key, locale) {

            arguments[0] = this.t_path ? `${this.t_path}.${arguments[0]}` : arguments[0];

            return this.$te.apply(this, arguments);
        },
        base_submit(path = 'model', callback = 'submit', processingPath = 'processing') {

            let processing;

            if (processingPath) {

                processing = get(this, processingPath);
            }

            if (!processing && this.base_validate(path)) {

                set(this, processingPath, true);

                this[callback]();
            }
        },
        base_validate(path = '') {

            if ('boolean' === typeof this.validated) {

                this.validated = true;
            }

            if (path && get(this.v$, `${path}`)) {

                get(this.v$, `${path}`).$touch();
            } else {

                this.v$.$touch();
            }

            return !get(this.v$, `${path ? path + '.' : ''}$invalid`);
        },
        base_error(error, redirect = false, options = {}) {

            if (redirect && error.response && error.response.status) {

                return this.$router.push({
                    name: "errors.code",
                    params: {
                        code: error.response.status
                    },
                    query: {
                        message: get(error, "response.data.results.0.msg")
                    }
                })
            }

            this
                .base_error_messages(
                    error,
                    undefined,
                    get(options, "translate", undefined),
                    get(options, "translateParameters", undefined),
                )
                .forEach(message => this.$toast.error(message));
        },
        base_error_messages(error, isString = false, translate = true, translateParameters = {}) {

            if (error.response && error.response.data && error.response.data.meta) {

                if (error.response.data.meta.error_code) {

                    // this.$te returns FALSE if non-default language does not have translation!!! So, we have to check translation if following way...
                    if (`errors.server.${error.response.data.meta.error_code}` !== this.$t(`errors.server.${error.response.data.meta.error_code}`, translateParameters)) {

                        return true === translate
                            ? (true === isString
                                    ? this.$t(`errors.server.${error.response.data.meta.error_code}`, translateParameters)
                                    : [this.$t(`errors.server.${error.response.data.meta.error_code}`, translateParameters)]
                            )
                            : (true === isString
                                    ? `errors.server.${error.response.data.meta.error_code}`
                                    : [`errors.server.${error.response.data.meta.error_code}`]
                            );
                    }
                }

                if (error.response.data.results) {

                    const messages = error.response.data.results
                        .map(message => message.msg ? message.msg : message)
                        .map(
                            message => true === translate
                            && `errors.server.${message}` !== this.$t(`errors.server.${message}`, translateParameters)
                                ? this.$t(`errors.server.${message}`, translateParameters)
                                : message
                        );

                    return true === isString ? messages.join("; ") : messages;
                }

                if ("undefined" !== typeof error.response.data.meta.message && 0 < error.response.data.meta.message.length) {

                    const result = error.response.data.meta.message.map(
                        message => true === translate && `errors.server.${message}` !== this.$t(`errors.server.${message}`, translateParameters)
                            ? this.$t(`errors.server.${message}`, translateParameters)
                            : message
                    );

                    return true === isString ? result.join("; ") : result;
                }
            }

            return true === isString ? error.toString() : [error.toString()];
        }
    }
}